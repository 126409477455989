<script setup lang="ts">
import definitions from '@respell/steps';
import { useVueFlow } from '@vue-flow/core';

const canvasStore = useCanvasStore();
const { errors } = storeToRefs(canvasStore);

const { addSelectedNodes } = useVueFlow({
  id: 'editor',
});

const stepsWithIssues = computed(() => {
  return Object.entries(errors.value)
    .filter(([_, stepErrors]) => stepErrors.length)
    .map(([slug, stepErrors]) => {
      const step = canvasStore.elements.find(
        (step) => step.data?.slug === slug,
      );
      if (!step) return;
      const stepKey = step?.data?.key;
      return [
        {
          label: step.label ?? definitions[stepKey]?.name,
          icon: definitions[stepKey]?.icon,
          issues: stepErrors,
          options: definitions[stepKey]?.options,
          slot: 'step',
          click: async () => addSelectedNodes([step]),
        },
      ];
    });
});

const issueCount = computed(() => stepsWithIssues.value?.length);
</script>
<template>
  <UDropdown
    v-if="stepsWithIssues?.length"
    :items="stepsWithIssues"
    :ui="{ rounded: 'rounded-xl' }"
  >
    <UButton
      icon="i-ph-warning-circle"
      size="xl"
      color="yellow"
      variant="solid"
      :label="`${issueCount} step${issueCount > 1 ? 's' : ''} to fix`"
      :trailing="false"
      :ui="{
        variant: {
          solid: 'bg-yellow-100 text-yellow-700 hover:bg-yellow-200',
        },
      }"
    />

    <template #step="{ item: step }">
      <UAlert
        color="white"
        variant="soft"
        :icon="step.icon"
        :title="step.label"
        class="w-96"
        :ui="{
          title: 'font-bold text-lg text-left',
        }"
      >
        <template #icon="{ icon }">
          <UIcon
            :name="icon"
            class="rounded-full border-gray-50 outline outline-gray-200 border bg-white text-2xl"
          />
        </template>
        <template #description>
          <ul class="list-disc space-y-2 text-red-500">
            <li
              v-for="(error, index) in step.issues"
              :key="index"
              class="text-left"
            >
              {{ step.options[error.path]?.name ?? error.path }}:
              {{ error.message }}
            </li>
          </ul>
        </template>
      </UAlert>
    </template>
  </UDropdown>
</template>

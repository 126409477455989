<script setup lang="ts">
import type { Graph } from '@respell/database';
import ConfirmModal from '~/components/modals/ConfirmModal.vue';

const props = defineProps<{
  spellId: string;
}>();
const spellStore = useSpellsStore();
const canvasStore = useCanvasStore();

const modal = useModal();

const { restoreGraph } = canvasStore;

const { data: versions, refresh } = await useAsyncCache(
  `graphs/${props.spellId}`,
  async () => spellStore.loadVersions(props.spellId as string),
  { watch: [props], lazy: true },
);

const versionOptions = computed(() => {
  return versions.value?.length
    ? versions.value
        ?.filter((graph: Graph) => graph.version !== 'v0')
        ?.map((graph: Graph) => [
          {
            label: graph.type === 'draft' ? 'Draft' : graph.version,
            icon:
              graph.type === 'draft'
                ? 'i-ph-pencil-simple'
                : graph.type === 'live'
                  ? 'i-ph-rocket'
                  : 'i-ph-clock-counter-clockwise',
            class:
              graph.type === 'draft' ? 'bg-gray-200 pointer-events-none' : '',
            click: async () => {
              if (graph.type !== 'draft') {
                modal.open(ConfirmModal, {
                  action: 'restore',
                  type: 'version',
                  message:
                    'Restoring this version will overwrite the current draft. Are you sure you want to continue?',
                  isDangerous: true,
                  async onConfirm() {
                    await restoreGraph(graph.id);
                    await refresh();
                  },
                });
              }
            },
          },
        ])
    : [];
});
</script>
<template>
  <UDropdown
    v-if="versionOptions.length"
    :items="versionOptions"
    :popper="{ placement: 'bottom-start' }"
  >
    <div class="flex flex-row items-center space-x-2 is-clickable">
      <UIcon
        name="i-ph-clock-counter-clockwise"
        class="text-3xl text-gray-600"
      />
      <p class="body dimmed">Version</p>
      <UIcon name="i-ph-caret-down-bold" class="text-xl text-gray-600" />
    </div>
  </UDropdown>
</template>

<script setup lang="ts">
import { PostHogEvents } from '@respell/utils/tracking/types';
import FixIssuesModal from '~/components/modals/FixIssuesModal.vue';

const modal = useModal();
const { $clientPosthog } = useNuxtApp();
const canvasStore = useCanvasStore();
const { canPublish, graph, errors } = storeToRefs(canvasStore);

const {
  pending,
  execute: publish,
  status,
} = useAsyncData(() => canvasStore.publishSpell(), {
  immediate: false,
});

const isPublishing = computed(() => pending.value && status.value !== 'idle');

const publishLabel = computed(() => {
  if (graph.value?.version == 'v0') {
    return isPublishing.value ? 'Publishing...' : 'Publish';
  } else {
    return isPublishing.value ? 'Updating...' : 'Update';
  }
});

const handlePublish = () => {
  $clientPosthog?.capture(PostHogEvents.UserPublishedSpell, {
    spellId: graph.value?.spellId,
  });
  if (
    Object.values(errors.value).some((stepErrors: any[]) => stepErrors.length)
  ) {
    modal.open(FixIssuesModal, {
      onConfirm() {
        publish();
        modal.close();
      },
    });
  } else {
    publish();
  }
};
</script>
<template>
  <UTooltip
    text="A start step or trigger is required to publish."
    :prevent="canPublish"
  >
    <UButton
      icon="i-ph-rocket-launch-fill"
      size="xl"
      color="primary"
      variant="solid"
      :loading="isPublishing"
      :disabled="!canPublish"
      :label="publishLabel"
      :trailing="false"
      @click="handlePublish"
    />
  </UTooltip>
</template>
